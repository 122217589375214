<script lang="ts" setup>
import { useI18n } from 'nuxt-i18n-composable'

import SkrButton from '@/components/button/SkrButton.vue'
import ResponsiveImage from '@/components/ResponsiveImage.vue'

const props = defineProps<{
  loading: boolean
}>()

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'action', data: Dialogs['DialogFreeMigration']['contextData']): void
}>()

const { t } = useI18n()

const submit = () => {
  if (props.loading) return
  emit('action', {})
}
</script>

<template>
  <v-dialog :value="true" max-width="796" persistent>
    <v-card>
      <v-card-title>
        {{ t('dialog.free_migration.title') }}
      </v-card-title>
      <v-container class="px-8 py-10" fluid>
        <v-row>
          <v-col cols="12" sm="7" class="d-flex flex-column space-y-6">
            <div class="text-h6 font-weight-bold grey-darker--text">{{ t('dialog.free_migration.content.title') }}</div>
            <div>
              <i18n path="dialog.free_migration.content.exclusions.text" tag="div">
                <template #link>
                  <a :href="t('dialog.free_migration.content.exclusions.link_url')" target="_blank" rel="noopener">{{
                    t('dialog.free_migration.content.exclusions.link_text')
                  }}</a>
                </template>
              </i18n>
              <ul>
                <li>{{ t('dialog.free_migration.content.exclusions.exclusion_1') }}</li>
                <li>{{ t('dialog.free_migration.content.exclusions.exclusion_2') }}</li>
                <li>{{ t('dialog.free_migration.content.exclusions.exclusion_3') }}</li>
              </ul>
            </div>
            <div>
              <i18n path="dialog.free_migration.content.legal_text">
                <template #gtc>
                  <a :href="t('global.accept_gtc.link_url')" target="_blank" rel="noopener">{{
                    t('global.accept_gtc.label_link_text')
                  }}</a>
                </template>
                <template #dpa>
                  <a :href="t('business.setup.gtc_dpa.dpa_link_url')" target="_blank" rel="noopener">{{
                    t('business.setup.gtc_dpa.dpa_link_text')
                  }}</a>
                </template>
              </i18n>
            </div>
            <skr-button data-cy="dialog_action_button" size="lg" @click="submit">
              {{ t('dialog.free_migration.action') }}
            </skr-button>
            <div class="text-body-2 text-center">
              <p>{{ t('dialog.free_migration.content.free_disclaimer.text') }}</p>
            </div>
          </v-col>
          <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="12" sm="5" align-self="center">
            <responsive-image source="dialog-company-building" :width="261" :height="272" />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
